import * as types from '../actions/types';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  app_key: '',
  access_token: '',
  session_token: '',
  user: {},
  appSetting: {
    form_control: 0,
    form_message_control: 0 // 0: normal display 1: display order number too much
  }
});

const appReducer = {
  app: (state = initialState, action) => {
    switch (action.type) {
      case types.LOADING:
        state = {...state, loading: action.data};
        return state;
      case types.SET_USER:
        state = {...state, user: action.data};
        return state;
      case types.SET_APP_SETTING:
        state = {...state, appSetting: action.data};
        return state;
      default:
        return state;
    }
  },
};

export default appReducer;
