import axios from 'axios';
import _ from 'lodash';

export class AuditTrailApi {
    async getOrderByUser(userId) {
        const response = (await axios.get(`jthk/api/orderInfo.php?user=staff${userId ? `&userId=${userId}` : ""}`)).data
        return response;
    }

    async getOrderHistory(orderNo) {
        const response = (await axios.get(`jthk/api/orderProcess.php?orderNo=${orderNo}`)).data
        return response;
    }
    async getOrderHistoryMore(orderNo){
        const response = (await axios.get(`jthk/api/orderInfo.php?orderNo=${orderNo}&trail=1`)).data
        return response;
    }

    async postOrderTrail(obj) {
        const response = (await axios.post(`jthk/api/orderProcess.php`, obj)).data
        return response;
    }

}

export class OrderApi {
    async getStatusDropDown() {
        const response = (await axios.get(`jthk/api/dropdownStatus.php`)).data
        return response;
    }
    async getPaymentOption() {
        const response = (await axios.get(`jthk/api/paymentOption.php`)).data
        return response;
    }
    async getOrderListByUser(){
        const response =  (await axios.get(`jthk/api/orderInfo.php?user=check`)).data
        return response
    }

    async getOrderListByAdmin(user){
        const response =  (await axios.post(`jthk/api/getUserOrderByAdmin.php`, {
        user
        })).data
        return response
    }

    async getOrderListByDate(startDate, endDate, orderTypeByDate, status){
        const response =  (await axios.get(`jthk/api/orderInfo.php?start=${startDate}&end=${endDate}&order_type=${orderTypeByDate}&status=${status}`)).data
        return response
    }

    async getOrderListByOrderNo(orderNoFrom, orderNoTo, orderTypeByRange, status = ''){
        const response =  (await axios.get(`jthk/api/orderInfo.php?orderNoFrom=${orderNoFrom}&orderNoTo=${orderNoTo}&order_type=${orderTypeByRange}&status=${status}`)).data
        return response
    }

    async getOrderListByUserId(userId, orderTypeByRange, status = '') {
        const response = (await axios.get(`jthk/api/orderInfo.php?orderUserId=${userId}&order_type=${orderTypeByRange}&status=${status}`)).data
        return response
    }
    
    async getAuditTrailByOrderNo(orderNoFrom, orderNoTo, orderTypeByRange, orderStatus){
        let response =  (await axios.get(`jthk/api/auditTrailByOrderNo.php?orderNoFrom=${orderNoFrom}&orderNoTo=${orderNoTo}&order_type=${orderTypeByRange}&orderStatus=${orderStatus}`)).data
        if (_.isArray(response)) {
            response = _.orderBy(response, ['ctime'], ['desc'])
            response = _.uniqBy(response, 'deliver_id')
        }
        return response
    }

    async getAuditTrailByUser({status, user}){
        let response =  (await axios.get(`jthk/api/auditTrailByUser.php?status=${status}&user=${user}`)).data
        if (_.isArray(response)) {
            response = _.orderBy(response, ['ctime'], ['desc'])
            response = _.uniqBy(response, 'deliver_id')
        }
        return response
    }

    async orderNew(postObj){
        const {
            sender,
            senderTel,
            senderAddr,
            receiver,
            receiverTel,
            receiverAddr,
            paymentMethod,
            remark,
            category,
            order_type,
            receiverPickpointId,
            senderPickpointId
        } = postObj;
        console.log('postObj', postObj)
        const response = (await axios.post(`jthk/api/orderNew.php?`,{
            sender,
            senderTel,
            senderAddr,
            receiver,
            receiverTel,
            receiverAddr,
            paymentMethod,
            remark: `${category}<br/>${remark}`,
            order_type,
            receiverPickpointId,
            senderPickpointId
        })).data
        console.log('response',response)
        return response
    }
    async orderQuery(orderNo,phone){
        const response =  (await axios.get(`jthk/api/orderInfo.php?no=${orderNo}&phone=${phone}`)).data
        return response
    }
    async getDeliverAddressByUser(){
        const response = (await axios.get(`jthk/api/deliverAddress.php`)).data
        return response
    }
    async deliverAddresssNew(name,tel,address,addressType){
        const response = (await axios.post(`jthk/api/deliverAddressNew.php?`,{
            name,tel,address,addressType
        })).data
        return response
    }
    async deliverAddresssPatch(id, name,tel,address, deleted){
        const response = (await axios.post(`jthk/api/deliverAddressNew.php?`,{
            id,name,tel,address,deleted
        })).data
        return response
    } 
}

export class AdminApi{
    async updateOrderDetail(dataObj){
        const response = (await axios.post(`jthk/api/orderPatch.php?`, dataObj)).data
        return response.status
    }

    async updateOrderPrice(obj) {
        const response = (await axios.post(`jthk/api/orderPricePatch.php?`, obj)).data
        return response.status
    }

    async updateOrderStatus(obj){
        const response = (await axios.post(`jthk/api/orderStatusPatch.php?`, obj)).data
        return response
    }

    async getOrderList(){
        const response =  (await axios.get(`jthk/api/orderInfo.php?token=853b739af9a1d8e6ef98eee3b9a0241e42fa9f8413447004340b457e72d0bc4f`)).data
        return response
    }
    async updateUserStatus(obj){
        const response = (await axios.post(`jthk/api/accountPatch.php?`,obj)).data
        return response.status
    }
}

export class AccountApi {
    
    async getAccountList(){
        const response = (await axios.post('jthk/api/accountList.php')).data
        return response
    }
    async register(username,password){
        const response = (await axios.post('jthk/api/accountCreate.php', {username, password})).data
        return response
    }
}

export class UtilApi{
    async login(username,password){
        const response = (await axios.post('jthk/api/login.php', {username, password})).data
        return response
    }

    async appSetting() {
        const response = (await axios.get('jthk/api/appSetting.php')).data
        return response;
    }

    async updateAppSetting(obj) {
        const response = (await axios.post('jthk/api/appSettingPatch.php', obj)).data
        return response;
    }

    async logout(){
        const response = (await axios.get('jthk/api/logout.php')).data
        return response
    }
    async sessionCheck() {
        const response = (await axios.get('jthk/api/accountInfo.php')).data
        return response
    }
}

export class MaintenanceApi {
    async insertPickup(code,address,name,description) {
        const response = (await axios.post('jthk/api/pickup.php', {action: 'add',code,name,address,description})).data
        return response;
    }
    async patchPickup(id,code,address,name,description) {
        const response = (await axios.post('jthk/api/pickup.php', {action: 'update',id,code,name,address,description})).data
        return response;
    }
    async deletePickup(id) {
        const response = (await axios.post('jthk/api/pickup.php', {action: 'delete',id})).data
        return response;
    }
    async queryPickup() {
            const response = (await axios.post('jthk/api/pickup.php', { action: 'query' })).data
        return response;
    }
}