import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

import reducer from '../reducers';

let loggerMiddleware;
let enhancer;
if (process.env.NODE_ENV === `development`) {
  loggerMiddleware = createLogger();
  enhancer = compose(
    applyMiddleware(
      thunk, // lets us dispatch() functions
      loggerMiddleware,
    ),
  );
} else {
  enhancer = compose(
    applyMiddleware(
      thunk, // lets us dispatch() functions
    ),
  );
}


let store;

export default function configureStore(initialState) {
  store = createStore(reducer, initialState, enhancer);
  return store;
}

export function getStore() {
  return store;
}
