import React, { Component } from 'react';
import { Button, Badge, Row, Col, FormGroup, Input, Card, CardBody } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import OrderModal from '../Admin/OrderModal';
import { OrderApi, AdminApi, AuditTrailApi } from '../../api/api';
import { replace } from 'seamless-immutable';
import QRCode from 'qrcode.react';
import QrReader from 'react-qr-reader'
import logo from '../../assets/img/brand/express_logo.jpeg';

import * as Main from '../../core/Main'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../redux/actions';

class OrderTrail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
  }
  
  async componentDidMount(params) {

    if (this.props.match.params.orderNo) {
      let { orderNo } = params ? params : this.props.match.params;
	  const result = await new AuditTrailApi().getOrderHistory(orderNo);
	  const resultMore = await new AuditTrailApi().getOrderHistoryMore(orderNo);
      this.setState({orders: result, orderMore: resultMore})
	}
  }

  componentWillReceiveProps(newProps){

	  if(newProps.match.params.orderNo !== this.props.match.params.orderNo){
		this.setState({orders: undefined, orderMore: undefined})
		this.componentDidMount(newProps.match.params)
	  }
  }

  render() {
		if(this.state.orders === undefined){
			return(
			<div className="sk-wandering-cubes">
			<div className="sk-cube sk-cube1"></div>
			<div className="sk-cube sk-cube2"></div>
			</div>
			)
		}
		else if (this.state.orderMore.length === 0) {
			return (
			
			<div  className="fullcenter">
				<span style={{fontSize:25}}>沒有訂單紀錄</span>
			</div>
			)
		}
		else if (this.state.orders.error) {
			return (
			
			<div  className="fullcenter">
				<span style={{fontSize:25}}>{this.state.orders.errorMessage}</span>
			</div>
			)
		}
    return (
      <div>
				<Card>
				
					<CardBody>
					<Row className="pb-4 pt-2">
					<Col lg={12} sm={12} md={12} xs={12} className="d-flex align-items-center justify-content-center col-auto px-0">
						<a href="https://5dexpress.hk" target="_blank" rel="noopener noreferrer"><img src={logo}  alt="5dexpress_logo" style={{width: 200 }}/></a>
					</Col>
				</Row>
				<h3 className="pb-3">訂單資料</h3>
				
				<Row className="d-flex">
				<Col lg={2} sm={12} md={12} xs={12} className="text-center text-lg-left mb-2 mb-lg-0">
			  <Button color="primary" className="btn-pill" size="lg" style={{fontSize: '1rem'}}>
				訂單: {this.props.match.params.orderNo}
			  </Button>
				</Col>
				<Col lg={2} sm={12} md={12} xs={12} className="text-center text-lg-left">
			  <Button color={this.state.orderMore[0].statusColor} className="btn-pill" size="lg" style={{fontSize: '1rem'}}>
				最新狀態: {this.state.orderMore.length>0 && this.state.orderMore[0].statusKey}
			  </Button>
				</Col>
				</Row>
				{/* <hr/> */}
			  {/* {this.state.orderMore.length>0  && this.state.orderMore[0].statusRemark ? 
				<>
				<hr/>
				<h3>訂單詳情</h3>
				{this.state.orderMore[0].statusRemark}
				</> : null} */}
				<hr className="mb-2 mt-4"/>
				<h3>訂單追蹤</h3>
        <BootstrapTable
					data={this.state.orders || []}
					version='4'
					bordered={false}
					striped
					hover
					options={{
						paginationSize: 5,
						hidePageListOnlyOnePage: true,
						alwaysShowAllBtns: false,
						withFirstAndLast: false,
						noDataText: '沒有追蹤紀錄',
					}}
				>
				<TableHeaderColumn
						dataField='ctime'
						thStyle={{ width: '30%' }}
						width='30%'
						dataFormat={(cell, row) => moment(cell).format('YYYY-MM-DD HH:mm')}
					>
						更新時間
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField='userId'
						thStyle={{ width: '30%' }}
						width='30%'
						isKey={true}
					>
						負責人
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField='statusKey'
						thStyle={{ width: '30%' }}
						width='30%'
						dataFormat={(cell, row) => <Badge color={row.statusColor}>{cell}</Badge>}
					>
						訂單狀態
					</TableHeaderColumn>
				</BootstrapTable>
				<br/>
				<Row><Col><p>Source: <a href="http://www.5dexpress.hk/"> 5dexpress.hk</a> </p> </Col></Row>

				</CardBody>
				</Card>

      </div>
    )
  }

}

function mapStateToProps(state) {
	return {
		app: state.app,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTrail);
