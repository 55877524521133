import React, { Component } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Input,
} from 'reactstrap';
import { AdminApi } from '../../api/api';
import * as Main from '../../core/Main';
import _ from 'lodash';
class OrderModal extends React.Component {
	render() {
		let statusValue = '';
		let statusRemark = '';
		let sender_price = '';
		let receiver_price = '';
		let prepaid_price = '';
		let weight = '';
		return (
			<div className='animated fadeIn'>
				<Modal
					className='modal-primary'
					isOpen={this.props.editOpen}
					toggle={this.props.toggleEdit}
					size={'lg'}
				>
					<ModalHeader>
						訂單編號：{this.props.updateOrderObj.orderNo}
					</ModalHeader>
					<ModalBody>
						<Label>訂單狀態: </Label>
						<Input
							type='select'
							defaultValue={this.props.updateOrderObj.statusValue}
							onChange={(e) => (statusValue = e.target.value)}
              disabled={!Main.isAdminPermission()}
						>
							{this.props.statusDropDown &&
								this.props.statusDropDown.map((x) => {
									return (
										<option key={x.key} value={x.value}>
											{x.key}
										</option>
									);
								})}
						</Input>
						<Label>訂單詳情: </Label>
						<Input
							type='textarea'
							rows={5}
							defaultValue={this.props.updateOrderObj.statusRemark}
							onChange={(e) => (statusRemark = e.target.value)}
						>
						</Input>
						<Label>重量: </Label>
						<Input
							type='number'
							defaultValue={this.props.updateOrderObj.weight}
							onChange={(e) => (weight = e.target.value)}
							disabled={!Main.isAdminPermission()}
						>
						</Input>
						<Label>寄付金額: </Label>
						<Input
							type='number'
							defaultValue={this.props.updateOrderObj.sender_price}
							onChange={(e) => (sender_price = e.target.value)}
							disabled={!Main.isAdminPermission()}
						>
						</Input>
						<Label>到付金額: </Label>
						<Input
							type='number'
							defaultValue={this.props.updateOrderObj.receiver_price}
							onChange={(e) => (receiver_price = e.target.value)}
							disabled={!Main.isAdminPermission()}
						>
						</Input>
						<Label>預繳金額: </Label>
						<Input
							type='number'
							defaultValue={this.props.updateOrderObj.prepaid_price}
							onChange={(e) => (prepaid_price = e.target.value)}
							disabled={!Main.isAdminPermission()}
						>
						</Input>
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							onClick={async () => {
								statusValue = statusValue === '' ? this.props.updateOrderObj.statusValue : statusValue;
								statusRemark = statusRemark === '' ? this.props.updateOrderObj.statusRemark : statusRemark;
								sender_price = sender_price === '' ? this.props.updateOrderObj.sender_price : sender_price;
								receiver_price = receiver_price === '' ? this.props.updateOrderObj.receiver_price : receiver_price;
								prepaid_price = prepaid_price === '' ? this.props.updateOrderObj.prepaid_price : prepaid_price;
								weight = weight === '' ? this.props.updateOrderObj.weight : weight;
								let total_price = (_.toNumber(sender_price) + _.toNumber(receiver_price) + _.toNumber(prepaid_price));
								let dataObj = {
									orderNo: this.props.updateOrderObj.orderNo,
									status: statusValue,
									statusRemark,
									total_price,
									sender_price,
									receiver_price,
									prepaid_price,
									weight,
								}
								await new AdminApi().updateOrderDetail(dataObj);
								this.props.handleQuery();
								this.props.toggleEdit();
							}}
						>
							更新
						</Button>{' '}
						<Button
							onClick={() => {
								this.props.toggleEdit();
							}}
						>
							取消
						</Button>{' '}
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default OrderModal;
