import { getStore } from '../redux/store/configureStore';

const USER_PERMISSION = {
  CLIENT: 0,
  ADMIN: 1,
  DRIVER: 2,
  WAREHOUSE: 3,
  PICKUPSTATION: 4
}

export function checkPermission(name) {
  const { privilege_role } = getStore().getState().app.user;
  return privilege_role === USER_PERMISSION[name];
}

export function isDriverPermission() {
  return checkPermission('DRIVER');
}

export function isAdminPermission() {
  return checkPermission('ADMIN');
}

export function isWarehousePermission() {
  return checkPermission('WAREHOUSE');
}

export function isClientPermission() {
  return checkPermission('CLIENT');
}

export function isPickupStationPermission(){
  return checkPermission('PICKUPSTATION');
}

export const popupCenter = (url, title, w, h) => {
	// Fixes dual-screen position                             Most browsers      Firefox
	const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
	const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

	const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
	const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

	const systemZoom = width / window.screen.availWidth;
	const left = (width - w) / 2 / systemZoom + dualScreenLeft
	const top = (height - h) / 2 / systemZoom + dualScreenTop
	const newWindow = window.open(url, title, 
	  `
	  scrollbars=yes,
	  width=${w / systemZoom}, 
	  height=${h / systemZoom}, 
	  top=${top}, 
	  left=${left}
	  `
	)

	if (window.focus) newWindow.focus();
}

export function getAppSetting() {
	const { appSetting } = getStore().getState().app;
	return appSetting
}