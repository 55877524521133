import _ from 'lodash';
import * as types from './types';

export function setUser(user_info) {
  return {
    type: types.SET_USER,
    data: user_info,
  };
}

export function setAppSetting(appSetting) {
  return {
    type: types.SET_APP_SETTING,
    data: appSetting,
  };
}

