import React, { Component } from 'react';
import './App.scss';
import { Provider as ReduxProvider } from "react-redux";
import configureStore from './redux/store/configureStore';
import AppContainer from './AppContainer';

const store = configureStore();

const App = (props) => {
  return (
    <ReduxProvider store={store}>
      <AppContainer {...props} />
    </ReduxProvider>
  );
}

export default App;
